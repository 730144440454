import { Ods, ODSIcon } from '@/ts/types/general.types'

export const ods = [
  { index: 1, image: '/static/images/ods/01.png' },
  { index: 2, image: '/static/images/ods/02.png' },
  { index: 3, image: '/static/images/ods/03.png' },
  { index: 4, image: '/static/images/ods/04.png' },
  { index: 5, image: '/static/images/ods/05.png' },
  { index: 6, image: '/static/images/ods/06.png' },
  { index: 7, image: '/static/images/ods/07.png' },
  { index: 8, image: '/static/images/ods/08.png' },
  { index: 9, image: '/static/images/ods/09.png' },
  { index: 10, image: '/static/images/ods/10.png' },
  { index: 11, image: '/static/images/ods/11.png' },
  { index: 12, image: '/static/images/ods/12.png' },
  { index: 13, image: '/static/images/ods/13.png' },
  { index: 14, image: '/static/images/ods/14.png' },
  { index: 15, image: '/static/images/ods/15.png' },
  { index: 16, image: '/static/images/ods/16.png' },
  { index: 17, image: '/static/images/ods/17.png' },
  { index: 18, image: '/static/images/ods/objetives.jpg' },
]

export const areas = [
  {
    id: 1,
    icon: '/static/images/specialty/health.svg',
    iconColor: '/static/images/specialty/healthColor.svg',
    bgColor: 'bg-blue-500',
    title: () => `Seguridad y Calidad de Vida`,
    name: 'health',
    color: '#1474b8',
  },
  {
    id: 2,
    icon: '/static/images/specialty/pawprint.svg',
    iconColor: '/static/images/specialty/pawprintColor.svg',
    bgColor: 'bg-yellow-500',
    title: () => `Vida Animal`,
    name: 'animal',
    color: '#f1c40e',
  },
  {
    id: 3,
    icon: '/static/images/specialty/environment.svg',
    iconColor: '/static/images/specialty/environmentColor.svg',
    bgColor: 'bg-green-600',
    title: () => `Protección del Medio ambiente`,
    name: 'environment',
    color: '#278b40',
  },
  {
    id: 4,
    icon: '/static/images/specialty/community.svg',
    iconColor: '/static/images/specialty/communityColor.svg',
    bgColor: 'bg-purple-500',
    title: () => `Gestión Comunitaria`,
    name: 'community',
    color: '#8430ab',
  },
  {
    id: 5,
    icon: '/static/images/specialty/security.svg',
    iconColor: '/static/images/specialty/securityColor.svg',
    bgColor: 'bg-red-500',
    title: () => `Protección Infraestructura`,
    name: 'security',
    color: '#e25050',
  },
]

export const getAreaById = (areaID: number) => {
  return areas.find((area) => area.id === areaID)
}

export const getAreaByName = (areaName: string) => {
  return areas.find((area) => area.name === areaName)
}

export const getODSByIndex = (odsIndex: number) => {
  return odsIcon.find((ods) => ods.index === odsIndex)
}

export const getODSByName = (odsName: string) => {
  return odsIcon.find((ods) => ods.title() === odsName)
}

export const getODSImageByIndex = (odsIndex: number) => {
  return ods.find((ods) => ods.index === odsIndex)?.image
}

export const odsIcon: ODSIcon[] = [
  {
    index: 1,
    image: '/static/images/ods/ods-01.svg',
    imageColor: '/static/images/ods/ods-01-color.svg',
    title: () => `Fin de la pobreza`,
    bgColor: 'bg-ods-01',
    textColor: 'text-ods-01',
    color: '#DA263D',
    desc: () => `Poner fin a la pobreza en todas sus formas en todo el mundo`,
    numbers:
      () => `Unos 783 millones de personas vive por debajo del umbral de pobreza internacional, con 1,90 dólares diarios.
    Las altas tasas de pobreza se encuentran a menudo en los países pequeños, vulnerables y afectados por los conflictos.`,
    objectives: () =>
      `  Para 2030, erradicar la pobreza extrema para todas las personas en el mundo, actualmente medida por un ingreso por persona inferior a 1,25 dólares al día y reducir al menos a la mitad la proporción de hombres, mujeres y niños y niñas de todas las edades que viven en la pobreza en todas sus dimensiones con arreglo a las definiciones nacionales.`,
  },
  {
    index: 2,
    image: '/static/images/ods/ods-02.svg',
    imageColor: '/static/images/ods/ods-02-color.svg',
    title: () => `Hambre cero`,
    bgColor: 'bg-ods-02',
    textColor: 'text-ods-02',
    color: '#CA9435',
    desc: () => `Poner fin al hambre`,
    numbers:
      () => `Una de cada nueve personas en el mundo está subalimentada en la actualidad; esto es, alrededor de 815 millones de personas en el mundo.
La pobre nutrición causa cerca de la mitad (45 por ciento) de las muertes en los niños menores de 5 años – 3.1 mil niños cada año.`,
    objectives: () =>
      `Para 2030, poner fin al hambre y asegurar el acceso de todas las personas, en particular los pobres y las personas en situaciones vulnerables, incluidos los lactantes, a una alimentación sana, nutritiva y suficiente durante todo el año.`,
  },
  {
    index: 3,
    image: '/static/images/ods/ods-03.svg',
    imageColor: '/static/images/ods/ods-03-color.svg',
    title: () => `Salud y Bienestar`,
    bgColor: 'bg-ods-03',
    textColor: 'text-ods-03',
    color: '#439439',
    desc: () => `Garantizar una vida sana y promover el bienestar para todos en todas las edades`,
    numbers:
      () => `Cada día mueren 17.000 niños menos que en 1990, pero más de cinco millones de niños mueren cada año hoy en día antes de cumplir los 5 años.
Tan solo la mitad de las mujeres de las regiones en desarrollo reciben la cantidad recomendada de atención médica que necesitan.
El sida es ahora la principal causa de muerte entre los adolescentes (de 10 a 19 años) en África y la segunda causa más común de muerte entre los adolescentes en todo el mundo.`,
    objectives: () =>
      `Lograr la cobertura sanitaria universal, en particular la protección contra los riesgos financieros, el acceso a servicios de salud esenciales de calidad y el acceso a medicamentos y vacunas seguros, eficaces, asequibles y de calidad para todos.`,
  },
  {
    index: 4,
    image: '/static/images/ods/ods-04.svg',
    imageColor: '/static/images/ods/ods-04-color.svg',
    title: () => `Educación de calidad`,
    bgColor: 'bg-ods-04',
    textColor: 'text-ods-04',
    color: '#BC182D',
    desc: () =>
      `Garantizar una educación inclusiva, equitativa y de calidad y promover oportunidades de aprendizaje durante toda la vida para todos`,
    numbers:
      () => `Se estima que el 50% de los niños que no asisten a la escuela primaria viven en zonas afectadas por conflictos.
Más de la mitad de los niños que no están matriculados en la escuela viven en el África Subsahariana.`,
    objectives: () =>
      `De aquí a 2030, asegurar que todas las niñas y todos los niños terminen la enseñanza primaria y secundaria, que ha de ser gratuita, equitativa y de calidad y producir resultados de aprendizaje pertinentes y efectivos.`,
  },
  {
    index: 5,
    image: '/static/images/ods/ods-05.svg',
    imageColor: '/static/images/ods/ods-05-color.svg',
    title: () => `Igualdad de género`,
    bgColor: 'bg-ods-05',
    textColor: 'text-ods-05',
    color: '#EB382F',
    desc: () => `Lograr la igualdad entre los géneros y empoderar a todas las mujeres y las niñas`,
    numbers:
      () => `A nivel mundial, 750 millones de mujeres y niñas se casaron antes de los 18 años y al menos 200 millones de mujeres y niñas en 30 países se sometieron a la mutilación genital femenina (MGF).
Una de cada cinco mujeres y niñas, incluido el 19% de las mujeres y las niñas de 15 a 49 años, han sufrido violencia física y/o sexual por parte de una pareja íntima, durante los últimos 12 meses. Sin embargo, en 49 países no existen leyes que protejan específicamente a las mujeres contra tal violencia.
    Las altas tasas de pobreza se encuentran a menudo en los países pequeños, vulnerables y afectados por los conflictos.`,
    objectives:
      () => `Poner fin a todas las formas de discriminación contra todas las mujeres y las niñas en todo el mundo.
Aprobar y fortalecer políticas acertadas y leyes aplicables para promover la igualdad de género y el empoderamiento de todas las mujeres y las niñas a todos los niveles`,
  },
  {
    index: 6,
    image: '/static/images/ods/ods-06.svg',
    imageColor: '/static/images/ods/ods-06-color.svg',
    title: () => `Agua limpia y saneamiento`,
    bgColor: 'bg-ods-06',
    textColor: 'text-ods-06',
    color: '#15A5D0',
    desc: () => `Garantizar la disponibilidad de agua y su gestión sostenible y el saneamiento para todos`,
    numbers:
      () => `La escasez de agua afecta a más del 40% de la población mundial y se prevé que esta porcentaje aumente. Más de 1700 millones de personas viven actualmente en cuencas fluviales en las que el consumo de agua supera la recarga.
4 billones de personas carecen de acceso a servicios básicos de saneamiento, como retretes o letrinas.`,
    objectives: () =>
      ` De aquí a 2030, lograr el acceso universal y equitativo al agua potable a un precio asequible para todos y  el acceso a servicios de saneamiento e higiene adecuados y equitativos para todos y poner fin a la defecación al aire libre, prestando especial atención a las necesidades de las mujeres y las niñas y las personas en situaciones de vulnerabilidad`,
  },
  {
    index: 7,
    image: '/static/images/ods/ods-07.svg',
    imageColor: '/static/images/ods/ods-07-color.svg',
    title: () => `Energía asequible y no contaminante`,
    bgColor: 'bg-ods-07',
    textColor: 'text-ods-07',
    color: '#FBAD31',
    desc: () => `Garantizar el acceso a una energía asequible, segura, sostenible y moderna`,
    numbers:
      () => `La energía es el factor que contribuye principalmente al cambio climático y representa alrededor del 60% de todas las emisiones mundiales de gases de efecto invernadero.
3000 millones de personas dependen de la madera, el carbón, el carbón vegetal o los desechos de origen animal para cocinar y calentar la comida.`,
    objectives: () =>
      `  De aquí a 2030, garantizar el acceso universal a servicios energéticos asequibles, fiables y modernos, y duplicar la tasa mundial de mejora de la eficiencia energética`,
  },
  {
    index: 8,
    image: '/static/images/ods/ods-08.svg',
    imageColor: '/static/images/ods/ods-08-color.svg',
    title: () => `Trabajo decente y crecimiento económico`,
    bgColor: 'bg-ods-08',
    textColor: 'text-ods-08',
    color: '#831732',
    desc: () => `Promover el crecimiento económico inclusivo y sostenible, el empleo y el trabajo decente para todos`,
    numbers:
      () => `En todo el mundo, en 2016 el 61% de los trabajadores tenía un empleo no regulado. Exceptuando el sector agrícola, el 51% de todos los trabajadores se incluyeron en esta categoría de empleo.
Los hombres ganan 12,5% más que las mujeres en 40 de los 45 países de los que se tienen datos.`,
    objectives: () =>
      `Promover políticas orientadas al desarrollo que apoyen las actividades productivas, la creación de puestos de trabajo decentes, el emprendimiento, la creatividad y la innovación, y fomentar la formalización y el crecimiento de las microempresas y las pequeñas y medianas empresas, incluso mediante el acceso a servicios financieros.`,
  },
  {
    index: 9,
    image: '/static/images/ods/ods-09.svg',
    imageColor: '/static/images/ods/ods-09-color.svg',
    title: () => `Industria Innovación e Infraestructura`,
    bgColor: 'bg-ods-09',
    textColor: 'text-ods-09',
    color: '#F0612F',
    desc: () =>
      `Construir infraestructuras resilientes, promover la industrialización sostenible y fomentar la innovación`,
    numbers:
      () => `La infraestructura básica, como las carreteras, las tecnologías de la información y la comunicación, el saneamiento, la energía eléctrica y el agua, sigue siendo escasa en muchos países en desarrollo
Las pequeñas y medianas empresas que se dedican al procesamiento industrial y la producción manufactura son las más críticas en las primeras etapas de la industrialización y, por lo general, son los mayores creadores de empleos. Constituyen más del 90% de las empresas de todo el mundo y representan entre el 50 y el 60% del empleo.`,
    objectives: () =>
      `Desarrollar infraestructuras fiables, sostenibles, resilientes y de calidad, incluidas infraestructuras regionales y transfronterizas, para apoyar el desarrollo económico y el bienestar humano, haciendo especial hincapié en el acceso asequible y equitativo para todos.`,
  },
  {
    index: 10,
    image: '/static/images/ods/ods-10.svg',
    imageColor: '/static/images/ods/ods-10-color.svg',
    title: () => `Reducción de las desigualdades`,
    bgColor: 'bg-ods-10',
    textColor: 'text-ods-10',
    color: '#DC1678',
    desc: () => `Reducir la desigualdad en y entre los países`,
    numbers:
      () => `Hasta el 30% de la desigualdad de ingresos se debe a la desigualdad dentro de los propios hogares, incluso entre mujeres y hombres. Además, las mujeres tienen más probabilidades que los hombres de vivir por debajo del 50% del ingreso medio.
En 2016, más del 64,4% de los productos que los los países en desarrollo exportaban a los mercados mundiales se encontraron con aranceles nulos, un aumento del 20% desde 2010.`,
    objectives: () =>
      `Garantizar la igualdad de oportunidades y reducir la desigualdad de resultados, incluso eliminando las leyes, políticas y prácticas discriminatorias y promoviendo legislaciones, políticas y medidas adecuadas a ese respecto.`,
  },
  {
    index: 11,
    image: '/static/images/ods/ods-11.svg',
    imageColor: '/static/images/ods/ods-11-color.svg',
    title: () => `Ciudades y comunidades sostenibles`,
    bgColor: 'bg-ods-11',
    textColor: 'text-ods-11',
    color: '#F79235',
    desc: () => `Lograr que las ciudades sean más inclusivas, seguras, resilientes y sostenibles`,
    numbers:
      () => `Las ciudades del mundo ocupan solo el 3% de la tierra, pero representan entre el 60% y el 80% del consumo de energía y el 75% de las emisiones de carbono.
Más de la mitad de la población urbana mundial estuvo expuesta a niveles de contaminación del aire al menos 2,5 veces más altos que el estándar de seguridad.`,
    objectives: () =>
      `De aquí a 2030, aumentar la urbanización inclusiva y sostenible y la capacidad para la planificación y la gestión participativas, integradas y sostenibles de los asentamientos humanos en todos los países.`,
  },
  {
    index: 12,
    image: '/static/images/ods/ods-12.svg',
    imageColor: '/static/images/ods/ods-12-color.svg',
    title: () => `Producción y consumo responsables`,
    bgColor: 'bg-ods-12',
    textColor: 'text-ods-12',
    color: '#C78133',
    desc: () => `Garantizar modalidades de consumo y producción sostenibles`,
    numbers:
      () => `Si la población mundial llegase a alcanzar los 9600 millones en 2050, se necesitaría el equivalente de casi tres planetas para proporcionar los recursos naturales precisos para mantener el estilo de vida actual.
El sector de la alimentación representa alrededor del 30% del consumo total de energía en el mundo y un 22% del total de las emisiones de gases de efecto invernadero.`,
    objectives: () =>
      `Lograr la gestión ecológicamente racional de los productos químicos y de todos los desechos a lo largo de su ciclo de vida, de conformidad con los marcos internacionales convenidos, y reducir significativamente su liberación a la atmósfera, el agua y el suelo a fin de minimizar sus efectos adversos en la salud humana y el medio ambiente.`,
  },
  {
    index: 13,
    image: '/static/images/ods/ods-13.svg',
    imageColor: '/static/images/ods/ods-13-color.svg',
    title: () => `Acción por el clima`,
    bgColor: 'bg-ods-13',
    textColor: 'text-ods-13',
    color: '#3F6B3A',
    desc: () => `Adoptar medidas urgentes para combatir el cambio climático y sus efectos`,
    numbers:
      () => `Dada la actual concentración y las continuas emisiones de gases de efecto invernadero, es probable que a finales de siglo el incremento de la temperatura mundial supere los 1,5 grados centígrados en comparación con el período comprendido entre 1850 y 1900 en todos los escenarios menos en uno.
Los océanos se han calentado, la cantidad de nieve y de hielo ha disminuido, y ha subido el nivel del mar. `,
    objectives:
      () => `Incorporar medidas relativas al cambio climático en las políticas, estrategias y planes nacionales
Mejorar la educación, la sensibilización y la capacidad humana e institucional respecto de la mitigación del cambio climático, la adaptación a él, la reducción de sus efectos y la alerta temprana`,
  },
  {
    index: 14,
    image: '/static/images/ods/ods-14.svg',
    imageColor: '/static/images/ods/ods-14-color.svg',
    title: () => `Vida submarina`,
    bgColor: 'bg-ods-14',
    textColor: 'text-ods-14',
    color: '#1172B0',
    desc: () => `Conservar y utilizar sosteniblemente los océanos, los mares y los recursos marinos`,
    numbers:
      () => `Los espacios de mar abierto muestran que los niveles actuales de acidez han aumentado en un 26 por ciento desde el comienzo de la Revolución Industrial.
Las aguas costeras se están deteriorando debido a la contaminación y la eutrofización. Sin esfuerzos coordinados, se espera que la eutrofización costera aumente en 20 por ciento de los grandes ecosistemas marinos para el año 2050.`,
    objectives:
      () => `De aquí a 2025, prevenir y reducir significativamente la contaminación marina de todo tipo, en particular la producida por actividades realizadas en tierra, incluidos los detritos marinos y la polución por nutrientes.
Minimizar y abordar los efectos de la acidificación de los océanos, incluso mediante una mayor cooperación científica a todos los niveles.
Facilitar el acceso de los pescadores artesanales a los recursos marinos y los mercados`,
  },
  {
    index: 15,
    image: '/static/images/ods/ods-15.svg',
    imageColor: '/static/images/ods/ods-15-color.svg',
    title: () => `Vida de ecosistemas terrestres`,
    bgColor: 'bg-ods-15',
    textColor: 'text-ods-15',
    color: '#37A647',
    desc: () =>
      `Gestionar sosteniblemente los bosques, luchar contra la desertificación, detener e invertir la degradación de las tierras, detener la pérdida de biodiversidad`,
    numbers:
      () => `Alrededor de 1.600 millones de personas dependen de los bosques para su sustento, incluidos 70 millones de personas indígenas.
2.600 millones de personas dependen directamente de la agricultura, pero el 52% de la tierra utilizada para la agricultura se ve moderada o severamente afectada por la degradación del suelo.
De las 8.300 razas de animales conocidas, el 8% está extinto y el 22% está en peligro de extinción.`,
    objectives:
      () => `Para 2030, luchar contra la desertificación, rehabilitar las tierras y los suelos degradados, incluidas las tierras afectadas por la desertificación, la sequía y las inundaciones, y procurar lograr un mundo con una degradación neutra del suelo.
 Adoptar medidas urgentes para poner fin a la caza furtiva y el tráfico de especies protegidas de flora y fauna y abordar la demanda y la oferta ilegales de productos silvestres`,
  },
  {
    index: 16,
    image: '/static/images/ods/ods-16.svg',
    imageColor: '/static/images/ods/ods-16-color.svg',
    title: () => `Paz, Justicia e Instituciones sólidas`,
    bgColor: 'bg-ods-16',
    textColor: 'text-ods-16',
    color: '#0D4C7D',
    desc: () => `Promover sociedades justas, pacíficas e inclusivas`,
    numbers: () => ``,
    objectives: () => ``,
  },
  {
    index: 17,
    image: '/static/images/ods/ods-17.svg',
    imageColor: '/static/images/ods/ods-17-color.svg',
    title: () => `Alianzas para lograr los objetivos`,
    bgColor: 'bg-ods-17',
    textColor: 'text-ods-17',
    color: '#19305B',
    desc: () => `Revitalizar la Alianza Mundial para el Desarrollo Sostenible`,
    numbers:
      () => `La asistencia oficial para el desarrollo se situó en $135.2 billones en 2014, el más alto nivel antes alcanzado.
Más de cuatro mil millones de personas no usan Internet, y el 90 por ciento de ellos son del mundo en desarrollo`,
    objectives:
      () => `Fortalecer la movilización de recursos internos, incluso mediante la prestación de apoyo internacional a los países en desarrollo, con el fin de mejorar la capacidad nacional para recaudar ingresos fiscales y de otra índole.
Mejorar la coherencia de las políticas para el desarrollo sostenible`,
  },
]
